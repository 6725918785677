import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import * as Styled from './Table.styles';
import verifyArray from '../../../../utils/verifyArray';
import verifyEmptyValues from '../../../../utils/verifyEmptyValues';
import Pagination from '../../../../components/Pagination/Pagination';

const Table = ({
  header,
  body,
  hasPagination,
  error,
  loading,
  filter,
  setFilter,
  pagination,
  errorMessage,
  filterTable,
  width,
  maxheight,
  padding,
}) => {
  return (
    <>
      <Styled.Container width={width} maxheight={maxheight} role="table">
        <Fragment>
          <Styled.TableComponent padding={padding} sticky="true" aria-label="sticky table">
            <TableHead>
              <Styled.Header>
                {verifyArray(header) &&
                  header.map((column) => (
                    <TableCell key={column.id} align={column.align}>
                      {column.name}
                    </TableCell>
                  ))}
              </Styled.Header>
            </TableHead>
            <TableBody>
              {verifyArray(body) &&
                body.map((row, index) => {
                  return (
                    <Styled.Row
                      key={'Table-' + index}
                      onClick={() => (row?.onClick ? row.onClick(row.data) : null)}
                      cursor={row?.onClick ? 'pointer' : null}
                      role="table-row"
                    >
                      {verifyArray(row.data) &&
                        row.data.map((column, index) => {
                          console.log('column', column.info)
                          if (typeof column.info == 'function') {
                            return (
                              <Styled.Collum key={index} align={column.align} width={column.width}>
                                {column.info()}
                              </Styled.Collum>
                            )
                          }
                          return (
                            <Styled.Collum key={index} align={column.align} width={column.width}>
                              {verifyEmptyValues(column.info)}
                            </Styled.Collum>
                          );
                        })}
                    </Styled.Row>
                  );
                })}
            </TableBody>
          </Styled.TableComponent>
          <Styled.MessageContainer>
            {!loading && !error && body?.length === 0 && <h1>Nenhum Resultado Encontrado</h1>}
            {loading && <h1>Carregando Informações</h1>}
            {error && errorMessage ? <h1>{errorMessage}</h1> : null}
          </Styled.MessageContainer>
          {hasPagination && (
            <Pagination
              limit={filter.LIMIT}
              filter={filter}
              currentPage={filter?.PAGE}
              setFilter={setFilter}
              paginationData={pagination}
              filterTable={filterTable}
            />
          )}
        </Fragment>
      </Styled.Container>
    </>
  );
};

export default Table;

Table.propTypes = {
  header: PropTypes.arrayOf(PropTypes.object),
  body: PropTypes.any,
  hasPagination: PropTypes.bool,
  padding: PropTypes.string,
  error: PropTypes.bool,
  loading: PropTypes.bool,
  filter: PropTypes.object,
  setFilter: PropTypes.func,
  pagination: PropTypes.object,
  errorMessage: PropTypes.string,
  filterTable: PropTypes.func,
  width: PropTypes.string,
};
