import React, { useEffect, useState } from 'react';
import Wrapper from '../../components/Wrapper/Wrapper';
import Tabs from '../../components/Tabs/Tabs';
import PixKeys from './tabContent/PixKeys';
import PixTransactions from './tabContent/PixTransactions';
import { useDispatch } from 'react-redux';
import { pixTransactionsFetchAction } from '../../store/fetchActions/pixTransactionsActions';
import { pixKeysFetchAction } from '../../store/fetchActions/pixKeysActions';
import PixLimitRequest from './tabContent/PixLimitiRequest';
import { pixLimitRequestFetchAction } from '../../store/fetchActions/pixLimitRequestActions';

export default function GerenciarClientes() {
  const dispatch = useDispatch();

  const [tabIndex, setTabIndex] = useState(0);
  const labels = ['Transações', 'Chaves', 'Solicitação de limite'];

  useEffect(() => {
    if (tabIndex === 0) dispatch(pixTransactionsFetchAction());
    if (tabIndex === 1) dispatch(pixKeysFetchAction());
    if (tabIndex === 2) dispatch(pixLimitRequestFetchAction());
  }, [tabIndex]);

  return (
    <Wrapper title="Pix - Transações/Chaves">
      <Tabs tabIndex={tabIndex} setTabIndex={setTabIndex} labels={labels} variant="fullWidth" margin="1.5vw 0" />
      {(() => {
        switch (tabIndex) {
          case 0:
            return <PixTransactions value={tabIndex} index={0} />;
          case 1:
            return <PixKeys value={tabIndex} index={1} />;
          case 2:
            return <PixLimitRequest value={tabIndex} index={1} />;
        }
      })()}
    </Wrapper>
  );
}
