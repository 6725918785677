import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import Filter from '../../../components/Filter/Filter';
import { FirstFilterLine, SecondFilterLine } from '../../../components/Filter/Filter.styles';
import TextField from '../../../components/TextField';
import DateInput from '../../../components/DateInput';
import SelectOptions from '../../../components/SelectOptions';
import Modal from '../../../components/Modal/Modal';
import { translateMessage } from '../../../utils/functions/translateMessage';
import Button from '../../../components/Button/Button';
import { ImSearch } from 'react-icons/im';
import { LoadingContainer } from '../../GerenciarClientes/styles/GerenciarClientes.styles';
import { CircularProgress } from '@material-ui/core';
import formatCPF from '../../../utils/functions/formatCPF';
import formatCNPJ from '../../../utils/functions/formatCNPJ';
import formatMoney from '../../../utils/functions/formatMoneyBRL';
import useCallFilter from '../../../utils/hooks/useCallFilter';
import {
  pixLimitRequestDetailsFetchAction,
  pixLimitRequestFetchAction,
} from '../../../store/fetchActions/pixLimitRequestActions';
import { ConvertObjectToArrayCreditDetails } from './ConvertObjectsToArray';
import StatusContainer from '../../../components/StatusContainer/StatusContainer';
import { useTheme } from 'styled-components';
import moment from 'moment';
import InformationPixLimitRequest from '../components/InformationPixLimitRequest';
import getDateH from '../../../utils/functions/formatDateHora';
import Table from '../components/Table/Table';
import { Grid, Typography } from '@mui/material';
const header = [
  {
    id: 'data de registro',
    name: 'DATA',
    align: 'center',
  },
  {
    id: 'cpf/cnpj',
    name: 'CPF/CNPJ',
    align: 'left',
  },
  {
    id: 'nome',
    name: 'NOME',
    align: 'left',
  },
  {
    id: 'tipo do limite ',
    name: 'TIPO DO LIMITE',
    align: 'center',
  },
  {
    id: 'limite tranferência',
    name: 'LIMITE TRANSFERÊNCIA',
    align: 'center',
  },
  {
    id: 'limite diário',
    name: 'LIMITE DIÁRIO',
    align: 'right',
  },
  {
    id: 'status',
    name: 'STATUS',
    align: 'right',
  },
];

const arrayTransactionTypes = [
  { ID: 0, NOME: 'Todos' },
  { ID: 1, NOME: 'Noturno' },
  { ID: 2, NOME: 'Diurno' },
];

const arrayStatus = [
  { ID: 0, NOME: 'Todos' },
  { ID: 1, NOME: 'Aguardando' },
  { ID: 2, NOME: 'Aprovado' },
  { ID: 3, NOME: 'Negado' },
];

const pageOptions = [
  { ID: 10, NOME: 10 },
  { ID: 25, NOME: 25 },
  { ID: 50, NOME: 50 },
  { ID: 100, NOME: 100 },
];

const GridLimit = (limit, newLimit) => {
  const theme = useTheme();
  return (
    <Grid container>
      <Grid item xs={12}>
        R$ {formatMoney(limit)}
      </Grid>
      <Grid item xs={12}>
        <Typography sx={{ color: theme.colors.darklimeGreen }}>
        R$ {formatMoney(newLimit)}
        </Typography>
      </Grid>
    </Grid>
  )
}

const PixLimiteRequest = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [isModalInfoOpen, setIsModalInfoOpen] = useState(false);
  const [idTypeTransaction, setIdTypeTransaction] = useState(null);
  const [idTransaction, setIdTransaction] = useState(null);
  const [filterData, setFilterData] = useState('');
  const [filter, setFilter] = useState({
    SEARCH: '',
    LIMIT: 10,
    PAGE: 1,
    TIPO_LIMIT: '',
    DATA_INICIAL: '',
    DATA_FINAL: '',
    STATUS: '',
  });
  const [dateInicial, setDateInicial] = useState(null);
  const [dateFinal, setDateFinal] = useState(moment().format('YYYY-MM-DD'));

  const { handleSubmit, control } = useForm({});

  const {
    dataPixLimitRequest,
    loadingPixLimitRequest,
    errorLoadingPixLimitRequest,
    paginationPixLimitRequest,
    errorMessagePixLimitRequest,
    detailsPixLimitRequest,
    loadingDetailsPixLimitRequest,
    successDetailsPixLimitRequest,
    messageDetailsPixLimitRequest,
  } = useSelector((state) => state.pixLimitRequest);

  const filterTable = (data) => {
    data.DATA_INICIAL = dateInicial;
    data.DATA_FINAL = dateFinal;
    setFilterData(data);
  };

  const closeModalInfo = () => {
    setIsModalInfoOpen(false);
  };

  useCallFilter(filterData, setFilter, pixLimitRequestFetchAction, 0, 0);

  useEffect(() => {
    dispatch(pixLimitRequestFetchAction(filter));
  }, [filter.PAGE]);

  const detailsArray = ConvertObjectToArrayCreditDetails(detailsPixLimitRequest, idTypeTransaction);
  const body = dataPixLimitRequest?.map((transaction) => {
    return {
      data: [
        { info: getDateH(transaction.DATA_CRIACAO), align: 'center' },
        {
          info: transaction.CPFCNPJ?.length > 11 ? formatCNPJ(transaction.CPFCNPJ) : formatCPF(transaction.CPFCNPJ),
          align: 'left',
        },
        { info: transaction.NOME, align: 'left' },
        { info: transaction.TIPO_LIMITE_DESCRICAO, align: 'center' },
        { info: () => GridLimit(transaction.VALOR_TOTAL_ANTERIOR, transaction.VALOR_TOTAL	), align: 'rigth' },
        { info: () => GridLimit(transaction.VALOR_POR_TRANSFERENCIA_ANTERIOR, transaction.VALOR_POR_TRANSFERENCIA ) , align: 'rigth' },
        {
          info: transaction?.STATUS ? (
            <StatusContainer code={transaction?.STATUS} description={transaction?.DESCRICAO}></StatusContainer>
          ) : (
            '-----'
          ),
          align: 'center',
        },
      ],
      onClick: () => inspectTransaction(transaction.ID),
    };
  });

  function inspectTransaction(idSolitacao) {
    dispatch(pixLimitRequestDetailsFetchAction(idSolitacao));
    setIsModalInfoOpen(true);
  }

  return (
    <>
      <div>
        <Filter>
          <form onSubmit={handleSubmit(filterTable)}>
            <FirstFilterLine>
              <TextField label="Nome ou CPF/CNPJ" name={'SEARCH'} width={'25.042vw'} control={control} />
              <SelectOptions
                control={control}
                options={arrayTransactionTypes}
                name={'TIPO_LIMIT'}
                width={'10vw'}
                label={'Tipo do limite'}
                initialValue={0}
              />
              <SelectOptions
                label={'Status'}
                width={'10vw'}
                name={'STATUS'}
                control={control}
                options={arrayStatus}
                initialValue={'0'}
              />
              <DateInput
                label="Data Inicial"
                name={'DATA_INICIAL'}
                control={control}
                value={dateInicial}
                width={'10vw'}
                onChange={(newValue) =>
                  newValue?.$d ? setDateInicial(moment(newValue?.$d).format('YYYY-MM-DD')) : setDateInicial(null)
                }
              />
              <DateInput
                label="Data Final"
                name={'DATA_FINAL'}
                control={control}
                value={dateFinal}
                width={'10vw'}
                onChange={(newValue) =>
                  newValue?.$d ? setDateFinal(moment(newValue?.$d).format('YYYY-MM-DD')) : setDateFinal(null)
                }
              />
              <Button
                backgroundcolor={theme.colors.buttonSearch}
                width={'16.042vw'}
                hoverbgcolor={theme.colors.buttonSearchHover}
                hovercolor={theme.colors.buttonSearchHoverText}
                mycolor={theme.colors.buttonSearchText}
                type="submit"
              >
                <ImSearch className="default-svg" size={'1.181vw'} />
                <span>Buscar</span>
              </Button>
            </FirstFilterLine>
            <SecondFilterLine>
              <h5>Resultados por Página</h5>
              <SelectOptions control={control} options={pageOptions} name={'LIMIT'} initialValue={10} />
            </SecondFilterLine>
          </form>
        </Filter>
        {loadingPixLimitRequest ? (
          <LoadingContainer>
            <CircularProgress size={'5vw'} color="inherit" />
          </LoadingContainer>
        ) : (
          <Table
            loading={loadingPixLimitRequest}
            error={errorLoadingPixLimitRequest}
            hasPagination={true}
            filter={filter}
            pagination={paginationPixLimitRequest}
            setFilter={setFilter}
            errorMessage={errorMessagePixLimitRequest}
            filterTable={filterTable}
            body={body}
            header={header}
            limit={filter.LIMIT}
          />
        )}
        <Modal open={isModalInfoOpen} close={closeModalInfo} title="Solicitação de limite PIX" width={'47.889vw'}>
          {loadingDetailsPixLimitRequest ? (
            <LoadingContainer marginTop={'1vw'}>
              <CircularProgress size={'5vw'} color="inherit" />
            </LoadingContainer>
          ) : !successDetailsPixLimitRequest ? (
            <h1>{translateMessage(messageDetailsPixLimitRequest)}</h1>
          ) : (
            <InformationPixLimitRequest
              data={detailsPixLimitRequest}
              idTransaction={detailsPixLimitRequest.ID}
              showButton={detailsPixLimitRequest.STATUS == 2 || detailsPixLimitRequest.STATUS == 3 ? false : true}
              onClose={closeModalInfo}
            />
          )}
        </Modal>
      </div>
    </>
  );
};

export default PixLimiteRequest;
