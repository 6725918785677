import styled, { css } from 'styled-components';
import Divider from '@material-ui/core/Divider';

export const ContainerStyled = styled.div`
  width: 100%;
`;

export const TitleStyled = styled.span`
  margin-top: 0.764vw;
  font-family: Inter;
  font-weight: 600;
  font-style: normal;
  font-size: 1.389vw;
  line-height: 2.083vw;
`;

export const Bar = styled(Divider)`
  width: 100%;
  color: ${(props) => props.theme.colors.darkGrayishBlue};
`;

export const ParagraphName = styled.div`
  font-family: Inter;
  font-weight: 500;
  font-style: normal;
  font-size: 1.111vw;
  line-height: 1.667vw;
  color: ${(props) => (props.color ? props.color : props.theme.colors.veryDark_2)};
  margin-top: 0.764vw;
  height: 1.667vw;
`;

export const ParagraphValue = styled(ParagraphName)`
  font-weight: 400;
  color: ${(props) => (props.color ? props.color : props.theme.colors.darkGrayishBlue)};

  ${(props) =>
    props.bold &&
    css`
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: right;
    `}
`;

export const RowContainer = styled.div`
  border-bottom: 1.5px solid ${(props) => props.theme.colors.darkGrayishBlueTransparent};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 0.694vw;

  width: 100%;
  color: ${(props) => props.theme.colors.darkGrayishBlue};
  &:last-child {
    border: none;
  }
`;

// export const Divider

export const RowLimit = styled.div`
  /* border-bottom: 4.5px solid ${(props) => props.theme.colors.darkGrayishBlueTransparent}; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 0.694vw;

  width: 100%;
  color: ${(props) => props.theme.colors.darkGrayishBlue};
  &:last-child {
    border: none;
  }
`;

export const RowLimitContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TitleContainer = styled.div`
  padding-top: 1.4rem;
`;

export const ContainerButton = styled.div`
  display: flex;
  margin: 2rem 0;
  justify-content: space-between;
`;

export const SectionHistoric = styled.div`
  padding: 1rem 0;
`;

export const EditButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  h4 {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.15px;
    margin-top: 2rem;
  }
`;
