import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ModalTwoFactor from '../../../components/TwoFactor/ModalTwoFactor';
import * as Styled from './Information.styles';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import DropDown from '../../../components/DropDown/DropDown';
import {
  pixLimitRequestAproveFetchAction,
  pixLimitRequestNegadedFetchAction,
} from '../../../store/fetchActions/pixLimitRequestActions';
import getDateH from '../../../utils/functions/formatDateHora';
import formatCPF from '../../../utils/functions/formatCPF';
import formatMoney from '../../../utils/functions/formatMoneyBRL';
import ModalPassword from '../../../components/ModalPassword/ModalPassword';
import { ButtonContainer, SmallerModal } from '../../../components/ModalPassword/ModalPassword.styles';
import { MdErrorOutline } from 'react-icons/md';
import Button from '../../../components/Button/Button';
import { useTheme } from 'styled-components';
import TextField from '../../../components/TextField';
import Modal from '../../../components/Modal/Modal';
import { Divider, Grid, Typography } from '@mui/material';

export default function InformationPixLimitRequest({ title, data, color, bold, idTransaction, showButton, onClose }) {
  const dispatch = useDispatch();
  const theme = useTheme();

  const [expanded, setExpanded] = useState('');
  const [disableButon, setDisableButon] = useState(false);
  const [showTwoFactorModal, setShowTwoFactorModal] = useState(false);
  const [isAprove, setIsAprove] = useState(false);
  const [showJustification, setShowJustification] = useState(false);

  const { handleSubmit, control } = useForm({});

  const handleDataForm = () => {
    if (isAprove) return aproved();
    setShowJustification(true);
  };

  const deny = ({ justificativa }) => {
    dispatch(
      pixLimitRequestNegadedFetchAction(
        {
          ID: idTransaction,
          JUSTIFICATIVA: justificativa ?? '',
        },
        onClose(),
      ),
    );
  };

  const aproved = () => {
    setDisableButon(true);
    dispatch(
      pixLimitRequestAproveFetchAction(
        {
          ID: idTransaction,
        },
        onClose(),
      ),
    );
  };

  const verifyAction = (action) => {
    if (action === 'deny') {
      setShowTwoFactorModal(true);
      setIsAprove(false);
      return;
    }
    setShowTwoFactorModal(true);
    setIsAprove(true);
  };

  return (
    <Styled.ContainerStyled>
      <Styled.TitleStyled>{title}</Styled.TitleStyled>
      <>
        <Styled.RowContainer role={'information-row'}>
          <Styled.ParagraphName>Data da solicitação</Styled.ParagraphName>
          <Styled.ParagraphValue color={color} bold={bold}>
            {getDateH(data.DATA_CRIACAO)}
          </Styled.ParagraphValue>
        </Styled.RowContainer>
        <Styled.RowContainer role={'information-row'}>
          <Styled.ParagraphName>Nome</Styled.ParagraphName>
          <Styled.ParagraphValue color={color} bold={bold}>
            {data.NOME}
          </Styled.ParagraphValue>
        </Styled.RowContainer>
        <Styled.RowContainer role={'information-row'}>
          <Styled.ParagraphName>Documento</Styled.ParagraphName>
          <Styled.ParagraphValue color={color} bold={bold}>
            {formatCPF(data.CPFCNPJ)}
          </Styled.ParagraphValue>
        </Styled.RowContainer>
        <Styled.RowContainer role={'information-row'}>
          <Styled.ParagraphName>Tipo de limite</Styled.ParagraphName>
          <Styled.ParagraphValue color={color} bold={bold}>
            {data.TIPO_LIMITE_DESCRICAO}
          </Styled.ParagraphValue>
        </Styled.RowContainer>
        <Styled.RowContainer role={'information-row'}>
          <Styled.ParagraphName>Status</Styled.ParagraphName>
          <Styled.ParagraphValue color={color} bold={bold}>
            {data.DESCRICAO}
          </Styled.ParagraphValue>
        </Styled.RowContainer>
        <Styled.RowLimit role={'information-row'}>
          <Styled.ParagraphName>Justificativa</Styled.ParagraphName>
          <Styled.ParagraphValue color={color} bold={bold}>
            {data.JUSTIFICATIVA ?? '---'}
          </Styled.ParagraphValue>
        </Styled.RowLimit>
        <Styled.RowLimitContainer>
          <Divider sx={{ border: 1, borderColor: '#E0E0E0' }} />
          <Styled.RowLimit role={'information-row'}>
            <Styled.ParagraphName>Limite diário solicitado</Styled.ParagraphName>
            <Styled.ParagraphValue color={color} bold={bold}>
              {'R$ ' + formatMoney(data.VALOR_TOTAL)}
            </Styled.ParagraphValue>
          </Styled.RowLimit>
          <Grid sx={{ paddingBottom: ' 0.694vw' }}>
            <Typography variant="caption">
              {'Atual: R$ ' + formatMoney(data.VALOR_TOTAL_ANTERIOR)}
            </Typography>
          </Grid>
        </Styled.RowLimitContainer>
        <Divider sx={{ border: 1, borderColor: '#E0E0E0' }} />
        <Styled.RowLimitContainer>
        <Styled.RowLimit role={'information-row'}>
          <Styled.ParagraphName>Limite por transferência solicitado</Styled.ParagraphName>
          <Styled.ParagraphValue color={color} bold={bold}>
            {'R$ ' + formatMoney(data.VALOR_POR_TRANSFERENCIA )}
          </Styled.ParagraphValue>
        </Styled.RowLimit>
        <Grid sx={{ paddingBottom: ' 0.694vw' }}>
            <Typography variant="caption">
              {'Atual: R$ ' + formatMoney(data.VALOR_POR_TRANSFERENCIA_ANTERIOR)}
            </Typography>
          </Grid>
          </Styled.RowLimitContainer>
        {/* <Styled.RowContainer role={'information-row'}>
          <Styled.ParagraphName>Limite por transferencia (Novo)</Styled.ParagraphName>
          <Styled.ParagraphValue color={color} bold={bold}>
            {'R$ ' + formatMoney(data.VALOR_POR_TRANSFERENCIA)}
          </Styled.ParagraphValue>
        </Styled.RowContainer> */}
        {showButton && (
          <Styled.EditButtonContainer>
            <DropDown
              openModalPassword={() => setExpanded(true)}
              name="Ações"
              options={[
                { label: 'Aprovar', action: 'approve' },
                { label: 'Negar', action: 'deny' },
              ]}
              setNameSelectedItem={(action) => verifyAction(action)}
            />
          </Styled.EditButtonContainer>
        )}
      </>
      <ModalPassword
        closeModal={() => setShowTwoFactorModal(false)}
        openModal={showTwoFactorModal}
        nextFunction={() => handleDataForm()}
      />
      {showJustification && (
        <Modal open={open} close={close}>
          <SmallerModal>
            <MdErrorOutline color={theme.colors.nutBrown} size={'6.944vw'} />
            <h3>Negar aumento de limite</h3>
            <h5>Tem certeza que deseja negar o aumento de limite?</h5>
            <h4>Justificativa</h4>
            <form onSubmit={handleSubmit(deny)}>
              <TextField
                variant={'outlined'}
                name={'justificativa'}
                width={'28.342vw'}
                multiline={true}
                minRows={3}
                required={true}
                control={control}
                inputProps={{ maxLength: 100 }}
              />
              <ButtonContainer>
                <Button
                  backgroundcolor={theme.colors.buttonBack}
                  width={'13.792vw'}
                  height={'3.125vw'}
                  hoverbgcolor={theme.colors.buttonBackHover}
                  hovercolor={theme.colors.buttonBackHoverText}
                  mycolor={theme.colors.buttonBackText}
                  onClick={() => {
                    setShowJustification(false);
                  }}
                >
                  <span>Voltar</span>
                </Button>
                <Button
                  backgroundcolor={theme.colors.buttonNegaded}
                  width={'13.792vw'}
                  height={'3.125vw'}
                  hoverbgcolor={theme.colors.buttonNegadedHover}
                  hovercolor={theme.colors.buttonNegadedHoverText}
                  mycolor={theme.colors.buttonNegadedText}
                  type="submit"
                >
                  <span>Negar</span>
                </Button>
              </ButtonContainer>
            </form>
          </SmallerModal>
        </Modal>
      )}
    </Styled.ContainerStyled>
  );
}

InformationPixLimitRequest.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  color: PropTypes.string,
  bold: PropTypes.bool,
  children: PropTypes.node,
  idTransaction: PropTypes.string,
};
