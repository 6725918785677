import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  dataPixLimitRequest: [],
  errorPixLimitRequest: false,
  errorLoadingPixLimitRequest: false,
  errorMessagePixLimitRequest: '',
  loadingPixLimitRequest: false,
  paginationPixLimitRequest: { totalPages: 0 },
  detailsPixLimitRequest: {},
  loadingDetailsPixLimitRequest: false,
  messageDetailsPixLimitRequest: '',
  successDetailsPixLimitRequest: false,
};

export const pixLimitRequestActionLoading = createAction('PIX_LIMIT_REQUEST_ACTION_LOADING');
export const pixLimitRequestActionSuccess = createAction('PIX_LIMIT_REQUEST_ACTION_SUCCESS');
export const pixLimitRequestActionError = createAction('PIX_LIMIT_REQUEST_ACTION_ERROR');

export const detailsPixLimitRequestActionLoading = createAction('DETAILS_PIX_LIMIT_REQUEST_ACTION_LOADING');
export const detailsPixLimitRequestActionSuccess = createAction('DETAILS_PIX_LIMIT_REQUEST_ACTION_SUCCESS');
export const detailsPixLimitRequestActionError = createAction('DETAILS_PIX_LIMIT_REQUEST_ACTION_ERROR');

export default createReducer(INITIAL_STATE, {
  [pixLimitRequestActionLoading.type]: (state) => {
    return {
      ...state,
      loadingPixLimitRequest: true,
      loadingDetailsPixLimitRequest: false,
      errorPixLimitRequest: false,
    };
  },
  [pixLimitRequestActionSuccess.type]: (state, payload) => {
    console.log('payload', payload)
    const { data, pagination } = payload?.payload?.data;
    return {
      ...state,
      loadingPixLimitRequest: false,
      loadingDetailsPixLimitRequest: false,
      errorPixLimitRequest: false,
      dataPixLimitRequest: data,
      paginationPixLimitRequest: pagination,
    };
  },
  [pixLimitRequestActionError.type]: (state, payload) => {
    return {
      ...state,
      loadingPixLimitRequest: false,
      loadingDetailsPixLimitRequest: false,
      errorPixLimitRequest: true,
      errorMessagePixLimitRequest: payload?.mensagem,
      dataPixLimitRequest: [],
      paginationPixLimitRequest: {},
    };
  },
  [detailsPixLimitRequestActionLoading.type]: (state) => {
    return {
      ...state,
      loadingDetailsPixLimitRequest: true,
    };
  },
  [detailsPixLimitRequestActionSuccess.type]: (state, payload) => {
    const { data, mensagem } = payload?.payload?.data;

    return {
      ...state,
      loadingDetailsPixLimitRequest: false,
      detailsPixLimitRequest: data,
      messageDetailsPixLimitRequest: mensagem,
      successDetailsPixLimitRequest: true,
    };
  },
  [detailsPixLimitRequestActionError.type]: (state, payload) => {
    return {
      ...state,
      loadingDetailsPixLimitRequest: false,
      detailsPixLimitRequest: [],
      messageDetailsPixLimitRequest: payload?.mensagem,
      successDetailsPixLimitRequest: false,
    };
  },
});
